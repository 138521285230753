// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import { AllBlogCategoryQuery } from '../../types/graphql-types'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Title, Section } from '../components/Typography'
import LabelIcon from '@material-ui/icons/LabelOutlined'
import AllBlogPost, { isOld, categoryMapping } from '../components/AllBlogPost'
import { GatsbyAwesomePaginationContext } from '../utils/types'
import Pagination from '../components/Pagination'

type Context = { category: string }

const useStyles = makeStyles(theme => ({
  selected: { backgroundColor: theme.palette.grey[200] },
}))

const BlogListCategory: React.FC<PageProps<
  AllBlogCategoryQuery,
  GatsbyAwesomePaginationContext & Context
>> = ({ data, pageContext }) => {
  const classes = useStyles()
  const category = pageContext.category
  const categoryName = data.allMicrocmsCategories.edges.find(
    ({ node }) => node.categoriesId === category
  )?.node.name
  const posts = [...data.allMicrocmsBlogs.edges, ...data.allWordpressJson.edges]
    .filter(edge =>
      isOld(edge)
        ? edge.node.categories
            ?.map(n => n != null && categoryMapping[n])
            .includes(category)
        : edge.node.categories?.map(c => c?.id).includes(category)
    )
    .slice(pageContext.skip, pageContext.skip + pageContext.limit)
  return (
    <Layout path="Blog" title="Blog">
      <Title>Blog</Title>
      <Typography variant="subtitle1" color="textSecondary">
        カテゴリー：{categoryName}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <AllBlogPost
            posts={posts}
            categories={data.allMicrocmsCategories.edges}
          />
          <Pagination {...pageContext} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Section>カテゴリー</Section>
          <List>
            {data.allMicrocmsCategories.edges.map(({ node }) => (
              <ListItem
                button
                divider
                component={Link}
                to={`/blog/categories/${node.categoriesId}`}
                className={
                  node.categoriesId === category ? classes.selected : undefined
                }
              >
                <ListItemIcon>
                  <LabelIcon />
                </ListItemIcon>
                <ListItemText>{node.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default BlogListCategory

export const query = graphql`
  query AllBlogCategory {
    allWordpressJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          excerpt {
            rendered
          }
          title {
            rendered
          }
          categories
          date
          jetpack_featured_media_url
        }
      }
    }
    file(relativePath: { eq: "logo_twitter.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMicrocmsBlogs(sort: { fields: date, order: DESC }) {
      edges {
        node {
          categories {
            name
            id
          }
          childConvertHtml {
            plainText
          }
          date
          blogsId
          title
          localThumbnail {
            childImageSharp {
              fixed(width: 150, height: 150, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allMicrocmsCategories {
      edges {
        node {
          name
          categoriesId
        }
      }
    }
  }
`
