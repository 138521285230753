import React from 'react'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import { GatsbyAwesomePaginationContext } from '../utils/types'
import { Link } from 'gatsby'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles(_theme => ({
  old: { textAlign: 'left' },
  new: { textAlign: 'right' },
}))

type Props = GatsbyAwesomePaginationContext & {
  className?: string
}

const Pagination: React.FC<Props> = ({
  className,
  previousPagePath,
  nextPagePath,
}) => {
  const classes = useStyles()
  return (
    <Grid container className={className}>
      <Grid item xs={6} className={classes.old}>
        {nextPagePath && (
          <Typography>
            <Button
              component={Link}
              to={nextPagePath}
              startIcon={<ArrowBackIcon />}
            >
              古い記事
            </Button>
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} className={classes.new}>
        {previousPagePath && (
          <Typography>
            <Button
              component={Link}
              to={previousPagePath}
              endIcon={<ArrowForwardIcon />}
            >
              新しい記事
            </Button>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Pagination
